<template>
  <section>
    <v-container>
      <v-row
        class="pt-15"
        justify="center"
        align="center"
      >
        <v-col
          cols="11"
          md="8"
          lg="8"
          xl="5"
        >
          <kn-form-title
            :title="title"
            :routerName="routerName"
          />
          <kn-tabs
            :items="tabs"
            @tabValue="setTabValue"
          />
          <kn-form-note-of-mandatory />

          <div v-if="valueDeterminate === 33">
            <kn-personal-info :model="employeePersonalInfo"/>

            <kn-form-subtitle title="Dirección" />
            <kn-address
              :address="employeeAddress"
              :fillFromSearch="fillAddressFrom"
            />

            <!-- <kn-form-subtitle title="IMSS" />
            <v-row>
              <v-col
                class="pb-0"
                cols="12"
                md="4"
                lg="4"
                xl="4"
              >
                <v-subheader>NSS:</v-subheader>
              </v-col>
              <v-col class="pb-0">
                <v-text-field
                  v-model="employeePersonalInfo.nss"
                  dense
                  outlined
                />
              </v-col>
            </v-row> -->

            <kn-form-subtitle title="Información de contacto" />
            <kn-contact-info :model="employeePersonalInfo" />

            <kn-form-subtitle title="Información institucional" />
            <kn-institutional-info :model="employeePersonalInfo" />
            
            <kn-doc-item
              v-if="employeePhoto === null"
              class="mt-2"
              idInput="fileInputPhoto"
              label="Fotografía:"
              docType="7"
              :docObj="photoDoc"
              @saveFile="savePhoto"
              @clearFile="clearPhoto"
            />
            <kn-image-doc-item
              v-if="!isNewMode && employeePhoto !== null"
              class="mt-2"
              label="Fotografía:"
              :image="employeePhoto"
              @deleteImage="clearImage"
            />
          </div>
          <div v-if="valueDeterminate === 66">
            <kn-cv-doc
              v-if="cvDoc === null || (cvDoc !== null && !cvDoc.id)"
              idInput="fileInputCV" 
              label="Puedes subir un archivo de CV o llenar el siguiente formulario"
              :docType="cvDocType"
              :docObj="cvDoc"
              @saveFile="saveCV"
              @clearFile="clearCV"
            />
            <kn-download-doc-item
              v-if="!isNewMode && cvDoc !== null && cvDoc.id"
              :doc="cvDoc"
              :column="true" 
              label="Archivo de CV" 
              @deleteDoc="clearCV"
            />
            <kn-form-subtitle title="Documentación" />
            <div 
              v-for="(doc, index) in docs"
              :key="`doc${index}`"
              class="my-4"
            >
              <kn-doc-item
                v-if="!doc.id"
                :idInput="`fileInput${index}`"
                :label="doc.label"
                :docType="doc.type"
                :docObj="findDocInDocsSelected(doc)"
                :isMandatory="false"
                @saveFile="saveFiles"
                @clearFile="clearFiles"
              />
              <kn-download-doc-item
                v-if="!isNewMode && doc.id"
                :doc="doc"
                :label="doc.label"
                @deleteDoc="deleteFile"
              />
            </div>
          </div>
          <div v-if="valueDeterminate === 100">
            <kn-tax-info :model="employeeTaxInfo" />
          </div>
          <kn-form-action-buttons
            :disableAccept="invalidFields"
            @accept="save()"
            @cancel="cancel()"
          />
        </v-col>
      </v-row>
    </v-container>
    <kn-back-to-top-button />
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loading"
      @action1="actionAlertBtn1"
      @action2="continueAdding"
    />
  </section>
</template>

<script>
import KnFormActionButtons from '@/components/KnFormActionButtons.vue'
import KnFormSubtitle from '@/components/KnFormSubtitle.vue'
import KnAddress from '@/components/forms/KnAddress.vue'
import KnContactInfo from '@/components/forms/KnContactInfo.vue'
import KnInstitutionalInfo from '@/components/forms/KnInstitutionalInfo.vue'
import KnPersonalInfo from '@/components/forms/KnPersonalInfo.vue'
import KnTaxInfo from '@/components/forms/KnTaxInfo.vue'
// import KnDocItem from '@/components/forms/KnDocItem.vue'
import { utilsMixin } from '@/mixins/utilsMixin'
import { generalRepoMixin } from '@/mixins/generalRepoMixin'
import { addressUtilsMixin } from '@/mixins/addressUtilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { api } from '@/api/axios-base'
import KnLocalAlert from '@/components/KnLocalAlert.vue'
import KnDownloadDocItem from '@/components/forms/KnDownloadDocItem.vue'
import KnTabs from '@/components/KnTabs.vue'
import { mapState } from 'vuex'
import KnImageDocItem from '@/components/forms/KnImageDocItem.vue'
import KnFormTitle from '@/components/KnFormTitle.vue'
import KnBackToTopButton from '@/components/forms/KnBackToTopButton.vue'
import KnCvDoc from '@/components/forms/KnCvDoc.vue'
import KnFormNoteOfMandatory from '@/components/KnFormNoteOfMandatory.vue'
export default {
  components: {
    KnPersonalInfo,
    KnFormSubtitle,
    KnFormActionButtons,
    KnTaxInfo,
    KnAddress,
    KnInstitutionalInfo,
    KnContactInfo,
    KnLocalAlert,
    KnDownloadDocItem,
    KnTabs,
    KnImageDocItem,
    KnDocItem: () => import('@/components/forms/KnDocItem.vue'),
    KnFormTitle,
    KnBackToTopButton,
    KnCvDoc,
    KnFormNoteOfMandatory,
  },
  mixins: [
    utilsMixin,
    generalRepoMixin,
    addressUtilsMixin,
    validationFormMixin
  ],
  props: {
    entity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      tabs: [
        { name: 'Datos personales', value: 33 },
        { name: 'Documentación', value: 66 },
        { name: 'Datos fiscales', value: 100 },
      ],
      valueDeterminate: 33,
      routerName: 'Empleados',
      employeePersonalInfo: {
        primer_nombre: null,
        segundo_nombre: '',
        apellido_paterno: null,
        apellido_materno: null,
        id_genero: null,
        fecha_nacimiento: null,
        edad: null,
        numero_identidad: '',
        titular_grupo: false,
        id_institucion_educativa: null,
      },
      employeeAddress: {
        estado: null,
        ciudad: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: ''
      },
      employeeTaxInfo: {
        tax_id: null,
        nombre_o_razon_social: null,
        id_uso_factura: null
      },
      employeeId: null,
      loading: false,
      fillAddressFrom: false,
      cvDoc: null,
      cvDocType: null,
      docs: [],
      docTypes: [],
      docsSelected: [],
      docsToDelete: [],
      photoDoc: null,
      employeePhoto: null
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.entity === null ? 'Agregar empleado' : 'Editar empleado'
    },
    isNewMode() {
      return (this.entity === null)
    },
    successAlertText() {
      return this.isNewMode ? 'Empleado registrado con exito!' : 'Empleado actualizado con exito!'
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info'
    },
    invalidFields() {
      // console.log('employeePersonalInfo tiene nulls?', this.objectHasNulls(this.employeePersonalInfo), this.employeePersonalInfo)
      // console.log('employeeAddress tiene nulls?', this.objectHasNulls(this.employeeAddress), this.employeeAddress)
      // console.log('employeeTaxInfo tiene nulls?', this.objectHasNulls(this.employeeTaxInfo), this.employeeTaxInfo)
      return this.isNewMode && (this.objectHasNulls(this.employeePersonalInfo)
              || this.objectHasNulls(this.employeeAddress)
              || this.objectHasNulls(this.employeeTaxInfo)
              || this.photoDoc === null)
    }
  },
  async created() {
    this.loading = true
    this.alertText = 'Por favor, espere. Cargando...'
    this.showAlert = true
    this.alertType = 'info'

    const allDocTypes = await this.fetchDocumentTypes()
    this.docTypes = allDocTypes.filter(docType => docType.dato !== 'Boleta de Calificaciones')
    this.buildDocArray()
    if (!this.isNewMode) {
      this.fillData()
    } else {
      this.employeePersonalInfo.id_institucion_educativa = this.institutionId
    }
    this.showAlert = false
  },
  methods: {
    async fetchDocumentTypes() {
      try {
        const response = await api.get('/personas/api/tipo-documento-all/')
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener tipos de documentos');
      }
    },
    async postEmployee(employeeObj) {
      try {
        const response = await api.post('/personas/crear-empleado', employeeObj)
        const responseData = await response.data
        // console.log('Respuesta empleado: ', responseData)
        if (responseData.e) {
          this.errors.push('No se pudo crear el empleado: ' + responseData.e.join(','))
          return
        }
        employeeObj.id = responseData.id_objeto
        this.employeeId = responseData.id_objeto
      } catch (error) {
        this.errors.push('Error al intentar crear empleado')
        console.error('Error al intentar crear empleado.', error);
      }
    },
    async patchEmployee(employeeObj) {
      try {
        const response = await api.patch('/personas/update-empleado', employeeObj)
        const responseData = await response.data
        // console.log('Respuesta empleado: ', responseData)
        if (responseData.e) {
          this.errors.push('No se pudo actualizar el empleado: ' + responseData.e.join(','))
          return
        }
        employeeObj.id = responseData.id_objeto
        this.employeeId = responseData.id_objeto
      } catch (error) {
        this.errors.push('Error al intentar actualizar empleado')
        console.error('Error al intentar actualizar empleado.', error);
      }
    },
    async createEmployee() {
      try {
        this.alertText = 'Creando empleado...'
        this.employeeAddress.numero_contacto = this.employeePersonalInfo.telefono_movil
        this.employeeAddress.id_institucion_educativa = this.institutionId
        this.alertText = 'Creando dirección de empleado'
        await this.postAddress(this.employeeAddress)
        // console.log('Direccion empleado: ', this.employeeAddress)

        this.employeePersonalInfo.id_institucion_educativa = this.institutionId
        this.employeePersonalInfo.id_direccion = this.employeeAddress.id
        this.employeePersonalInfo.telefono_casa = this.employeePersonalInfo.telefono_movil
        this.alertText = 'Creando datos personales de empleado'
        await this.postPersonalInfo(this.employeePersonalInfo)
        // console.log('Datos personales empleado: ', this.employeePersonalInfo)

        this.alertText = 'Creando datos fiscales de empleado'
        await this.postTaxInfo(this.employeeTaxInfo)
        // console.log('Datos fiscales empleado: ', this.employeeTaxInfo)

        const userObj = {
          nombre_usuario: this.generateUsername(this.employeePersonalInfo),
          password: this.generatePassword(this.employeePersonalInfo),
          correo: this.employeePersonalInfo.email,
          nombre: this.employeePersonalInfo.primer_nombre,
          apellido_paterno: this.employeePersonalInfo.apellido_paterno,
          id: null,
          is_staff: false
        }
        this.alertText = 'Creando usuario de empleado'
        await this.postUser(userObj)
        // console.log('Usuario empleado: ', userObj);
        
        this.alertText = `Usuario empleado: ${userObj.nombre_usuario} ${userObj.password}`

        const urlPhoto = this.photoDoc !== null ? await this.uploadFile(this.photoDoc) : null

        const employeeObj = {
          id_usuario: userObj.id,
          id_datos_personales: this.employeePersonalInfo.id,
          id_datos_fiscales: this.employeeTaxInfo.id,
          url_foto: urlPhoto !== null ? urlPhoto.url_archivo : '',
          id_institucion_educativa: this.institutionId,
          id_tipo_empleado: this.employeePersonalInfo.id_tipo_empleado,
          titular_grupo: this.employeePersonalInfo.titular_grupo,
          id_sucursal: 1
        }
        await this.postEmployee(employeeObj)
        // console.log('Empleado: ', employeeObj)
        
      } catch (error) {
        this.errors.push('Error al intentar crear empleado')
        console.error('Error al crear empleo.', error);
      }
    },
    async updateEmployee() {
      try {
        this.alertText = 'Actualizando empleado...'
        this.employeeAddress.numero_contacto = this.employeePersonalInfo.telefono_movil
        this.employeeAddress.id_institucion_educativa = this.institutionId
        this.alertText = 'Actualizando dirección de empleado'
        await this.updateAddress(this.employeeAddress)
        // console.log('Direccion empleado: ', this.employeeAddress)

        this.employeePersonalInfo.id_institucion_educativa = this.institutionId
        this.employeePersonalInfo.id_direccion = this.employeeAddress.id
        this.employeePersonalInfo.telefono_casa = this.employeePersonalInfo.telefono_movil
        this.employeePersonalInfo.numero_identidad_curp = ''
        this.alertText = 'Actualizando datos personales de empleado'
        await this.updatePersonalInfo(this.employeePersonalInfo)
        // console.log('Datos personales empleado: ', this.employeePersonalInfo)

        this.alertText = 'Actualizando datos fiscales de empleado'
        await this.updateTaxInfo(this.employeeTaxInfo)
        // console.log('Datos fiscales empleado: ', this.employeeTaxInfo)

        if (this.photoDoc !== null) {
          const urlPhoto = await this.uploadFile(this.photoDoc)
          const updatedPhoto = urlPhoto !== null 
                                ? await this.updateEmployeePhoto(urlPhoto.url_archivo, this.employeeId)
                                : ''
          if (updatedPhoto) {
            this.alertText = "Foto actualizada"
          }
          // console.log('Foto de empleado actualizada:', updatedPhoto);
        }

        const employeeObj = {
          id: this.employeeId,
          id_usuario: this.entity.usuario.id,
          id_datos_personales: this.employeePersonalInfo.id,
          id_datos_fiscales: this.employeeTaxInfo.id,
          id_institucion_educativa: this.institutionId,
          id_tipo_empleado: this.employeePersonalInfo.id_tipo_empleado,
          titular_grupo: this.employeePersonalInfo.titular_grupo,
          id_sucursal: 1
        }
        this.alertText = "Actualizando empleado"
        await this.patchEmployee(employeeObj)
        // console.log('Empleado: ', employeeObj)
      } catch (error) {
        this.errors.push('Error al intentar actualizar empleado')
        console.error('Error al intentar actualizar empleado.', error);
      }
    },
    async asignEmployeeToGroup(employeeId, groupId) {
      try {
        const response = await api.post('/personas/agregar-profesor-grupo', {
          id_empleado: employeeId,
          id_grupo: groupId
        })
        const responseData = await response.data
        if (responseData.mensaje && responseData.mensaje === 'operación exitosa') {
          this.alertText = 'Empleado asignado a grupo exitosamente'
        } else {
          this.errors.push('Ocurrió un problema, no se pudo asignar empleado a grupo')
        }
      } catch (error) {
        console.error('Error al asignar profesor a grupo.', error);
        this.errors.push('Error al intentar asignar empleado a grupo')
      }
    },
    async createObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true
        await this.createEmployee()
        if (!this.errors.length) {
          if (this.docsSelected.length) {
            await this.uploadDocs()
          }
          const groupId = this.employeePersonalInfo.grupo
          if (groupId !== undefined && groupId !== null) {
            await this.asignEmployeeToGroup(this.employeeId, groupId)
          }
        }
        if (this.errors.length) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
        this.loading = false
      } catch (error) {
        console.error('Error al dar de alta Empleado y objetos relacionados', error);
      }
    },
    async updateObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true
        await this.updateEmployee()
        if (this.errors.length === 0) {
          // Primero eliminamos los documentos que se
          // se van a actualizar
          this.alertText = 'Limpiando documentos'
          if (this.docsToDelete.length > 0) {
            for (const docId of this.docsToDelete) {
              await this.removeDocumentFromEmployee(docId, this.employeeId)
              await this.deleteDocumentPermanently(docId)
            }
          }
          // Despues se crean y asignan los nuevos
          this.alertText = 'Acualizando documentos'
          if (this.docsSelected.length > 0) {
            await this.uploadDocs()
          }
          // Solo se va a actualizar si el grupo seleccionado
          // es diferente del grupo ya asignado
          const groupId = this.employeePersonalInfo.grupo
          if (groupId !== undefined && groupId !== null
              && (groupId !== this.entity.groupId)) {
            await this.asignEmployeeToGroup(this.employeeId, groupId)
          }
        }
        this.loading = false
        if (this.errors.length > 0) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
      } catch (error) {
        this.loading = false
        // console.log('Error al actualizar Empleado y objetos relacionados', error);
        this.errors.push('Error al actualizar Empleado y objetos relacionados')
        this.alertType = 'error'
        this.alertText = this.errors.join(', ')

      }
    },
    setTabValue(val) {
      this.valueDeterminate = val
    },
    async save() {
      if (this.isNewMode) {
        this.createObjects()
      } else {
        this.updateObjects()
      }
    },
    cancel() {
      this.returnToTable()
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType  === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({name: this.routerName})
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    clean() {
      window.scroll({
        top: 50,
        left: 0,
        behavior: 'smooth'
      });
      this.valueDeterminate = 33
      this.employeePersonalInfo = {
        segundo_nombre: '',
        numero_identidad: ''
      }
      this.employeeAddress = {
        ciudad: '',
        numero_interior: ''
      }
      this.employeeTaxInfo = {}
      this.employeeId = null
      this.docs = []
      this.buildDocArray()

      this.docsSelected = []
      this.docsToDelete = []
    },
    async fillData() {
      this.employeeId = this.entity.id
      this.employeePhoto = this.entity.foto_perfil
      this.employeePersonalInfo.url_foto = this.employeePhoto
      this.employeePersonalInfo = {...this.entity.datos_personales}
      this.employeePersonalInfo.id_genero = this.employeePersonalInfo.genero
      this.employeePersonalInfo.id_institucion_educativa = this.entity.institucion_educativa.id
      this.employeePersonalInfo.id_tipo_empleado = this.entity.tipo_empleado.id
      this.employeePersonalInfo.grupo = this.entity.groupId
      this.employeePersonalInfo.titular_grupo = this.entity.titular_grupo
      this.fillAddressFrom = false
      const employeeAddress = await this.fetchAddresById(this.employeePersonalInfo.direccion, this.institutionId)
      this.employeeAddress = {...employeeAddress}
      this.employeeAddress.municipio = employeeAddress.codigo_postal.nombre_municipio
      this.fillAddressFrom = true
      this.employeeTaxInfo = {...this.entity.datos_fiscales}
      this.employeeTaxInfo.id_uso_factura = this.employeeTaxInfo.uso_factura

      if (this.entity.documentos_empleado.length) {
        const employeeDocs = [...this.entity.documentos_empleado]
        const cvType = this.findDocTypeByName('Curriculum Vitae')
        const cvDoc = employeeDocs.find(doc => doc.tipo_documento === cvType.id)
        if (cvDoc) {
          this.cvDoc = cvDoc
        }
        const copyDocs = this.docs
        copyDocs.forEach((doc, index) => {
          // console.log('Doc normal: ', doc);
          const docFinded = employeeDocs.find(employeeDoc => employeeDoc.tipo_documento === doc.type)
          if (docFinded) {
            const newDoc = {...doc, ...docFinded}
            this.docs[index] = newDoc
          }
          // console.log('doc final: ', doc);
        })
        // console.log('Docs llenado:', this.docs);
      }
    },
    /** Metodos para foto **/
    savePhoto(file) {
      // console.log('foto file:', file);
      this.photoDoc = file
    },
    clearPhoto() {
      this.photoDoc = null
    },
    clearImage() {
      this.employeePhoto = null
      this.employeePersonalInfo.url_foto = ''
    },
    async updateEmployeePhoto(urlPhoto, employeeId) {
      try {
        const response = await api.post('/personas/update-foto-perfil-empleado', {
          url_imagen: urlPhoto,
          id_empleado: employeeId
        })
        const responseData = response.data
        if (responseData.e) {
          this.errors.push('No se pudo actualizar la foto de empleado', responseData.e.join(', '))
          return
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar actualizar foto de perfil');
      }
    },
    /** Metodos para documentos */
    async buildDocArray() {
      try {
        this.docTypes.forEach(docType => {
          if (docType.dato.includes('Curriculum Vitae')) {
            this.cvDocType = docType.id
          } else {
            const newDoc = { type: docType.id, label: docType.dato, file: null }
            this.docs.push(newDoc)
          }
        })
      } catch (error) {
        console.error('Error al intentar construir array de documentos');
      }
    },
    findDocTypeByName(name) {
      return this.docTypes.find(docType => docType.dato.includes(name))
    },
    findDocInDocsSelected(doc) {
      // console.log('doc findDocInDocsSelected', doc);
      const docFinded = this.docsSelected.find(docSelected => docSelected.type === doc.type)
      // console.log('docFinded', docFinded);
      return docFinded ? docFinded : null
    },
    saveCV(file) {
      // console.log('file CV:', file);
      this.cvDoc = file
    },
    clearCV(file) {
      this.cvDoc = null
      if (!this.isNewMode && file.id !== null) {
        this.docsToDelete.push(file.id)
      }
    },
    saveFiles(file) {
      // console.log('El archivo seleccionado:', file);
      const fileIndex = this.docsSelected.findIndex(doc => doc.type === file.type)
      if (fileIndex !== -1) {
        this.docsSelected[fileIndex] = file
      } else {
        this.docsSelected.push(file)
      }
      // console.log('Array de archivos:', this.docsSelected);
    },
    clearFiles(file) {
      // console.log('Archivo a eliminar:', file)
      this.docsSelected = this.docsSelected.filter(doc => doc.file !== file.file)
      // console.log('Array de archivos:', this.docsSelected);
    },
    async deleteFile(file) {
      // console.log('Archivo a eliminar:', file)
      const copyDocs = [...this.docs]
      const resetFile = {
        id: null,
        type: file.type,
        label: file.label,
        file: null
      }
      this.docsToDelete.push(file.id)
      const fileIndex = copyDocs.findIndex(doc => doc.id === file.id)
      if (fileIndex !== -1) {
        copyDocs[fileIndex] = resetFile
        this.docs = copyDocs
      }
      // console.log('Docs despues de eliminar:', copyDocs);
      // await this.removeDocumentFromEmployee(file.id, this.employeeId)
      // await this.deleteDocumentPermanently(file.id)
    },
    async uploadDocs() {
      /** 
       * Por cada documento que se haya subido se va a realizar: 
       * Subir archivo,
       * Crear documento,
       * Asignar documento a empleado (se debe llamar esta función
       * una vez que ya se tenga el id de empleado)
      */
      this.alertText = 'Subiendo documentos'
      if (this.cvDoc !== null && !this.cvDoc.id) {
        this.docsSelected.push(this.cvDoc)
      }
      this.docsSelected.forEach(async docSelected => {
        // console.log('Documento a subir', docSelected);
        // Subiendo archivo
        this.alertText = 'Subiendo archivo'
        const responseUpload = await this.uploadFile(docSelected)
        if (responseUpload !== null) {
          // Creando documento
          const docObj = {
            nombre_documento: responseUpload.nombre_archivo,
            url_documento: responseUpload.url_archivo,
            id_tipo_documento: docSelected.type,
            id_autor: this.userData.id
          }
          this.alertText = 'Subiendo documento'
          const responseDoc = await this.createDocument(docObj)
          if (responseDoc 
              && responseDoc !== undefined 
              && responseDoc !== null) {
            // Agregando documento a empleado
            this.alertText = 'Agregando documento a empleado'
            const documentId = responseDoc.nuevo_documento
            await this.asignDocumentToEmployee(documentId, this.employeeId)
          }
        }
      });
    },
    async uploadFile(fileObj) {
      try {
        const formData = new FormData()
        formData.append('FILES', fileObj.file)
        const response = await api.post('/personas/subir-archivo', 
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        const responseData = await response.data
        // console.log('Respuesta subir archivo:', responseData);
        let fileUploaded = null
        if (responseData.archivos.length !== 0) {
          fileUploaded = responseData.archivos[0]
        } else {
          this.errors.push('No se subió el archivo')
        }
        return fileUploaded
      } catch (error) {
        this.errors.push('Error al intentar subir archivo')
        console.error('Error al intentar subir archivo');
      }
    },
    async createDocument(docObj) {
      try {
        const response =  await api.post('/personas/crear-documento', docObj)
        const responseData = await response.data
        // console.log('Respuesta crear documento', responseData);
        if (responseData.e) {
          this.errors.push('Error al crear documento: ' + responseData.e.join(', '))
          return
        }
        return responseData
      } catch (error) {
        this.errors.push('Error al intentar crear documento')
        console.error('Error a intentar crear documento');
      }
    },
    async asignDocumentToEmployee(documentId, employeeId) {
      try {
        const response = await api.post('/personas/agregar-documento-empleado', {
          id_empleado: employeeId,
          id_documento: documentId
        })
        const responseData = await response.data
        // console.log('Respuesta asignar documento a empleado: ', responseData);
        if (responseData.e) {
          this.errors.push('Error al asignar documento a empleado: ' + responseData.e.join(', '))
        }
      } catch (error) {
        this.errors.push('Error al intentar asignar documento a empleado')
      }
    },
    async removeDocumentFromEmployee(documentId, employeeId) {
      try {
        const response = await api.post('/personas/remove-documento-empleado', {
          id_empleado: employeeId,
          id_documento: documentId
        })
        const responseData = await response.data
        // console.log('Respuesta remover documento del empleado:', responseData);
        if (responseData.e) {
          this.errors.push('Error al remover documento del empledo: ' + responseData.e.join(', '))
        }
      } catch(error) {
        this.errors.push('Error al intentar remover documento al empleado')
        console.error('Error al intentar remover documento al empleado.', error);
      }
    },
    async deleteDocumentPermanently(documentId) {
      try {
        const response = await api.post('/personas/delete-documento-permanente', {
          id_documento: documentId
        })
        const responseData = await response.data
        // console.log('Respuesta eliminar documento permanentemente:', responseData);
        if (responseData.e) {
          this.errors.push('Error al eliminar documento permanentemente: ' + responseData.e.join(', '))
        }
      } catch (error) {
        this.errors.push('Error al intentar eliminar documento permanentemente')
        console.error('Error al intentar eliminar documento permanentemente.', error);
      }
    }
  }
}
</script>

<style>

</style>