<template>
  <section>
    <kn-select
      label="Institución educativa"
      v-model="model.id_institucion_educativa"
      :rules="[rules.required]"
      :items="institutions"
      item-text="nombre_comercial"
      item-value="id"
      @input="fullArraysByIE(model.id_institucion_educativa)"
    />
    <kn-select
      label="Tipo de empleado"
      v-model="model.id_tipo_empleado"
      :rules="[rules.required]"
      :items="employeeTypes"
      item-text="dato"
      item-value="id"
    />
    <kn-select
      label="Asignar grupo"
      v-model="model.grupo"
      :rules="[]"
      :isMandatory="false"
      :items="groups"
      item-text="nombre_grupo"
      item-value="id"
    />
    <kn-check-box
      class="mt-0"
      label="Titular de grupo"
      v-model="model.titular_grupo"
      :rules="[]"
      :isMandatory="false"
    />
  </section>
</template>

<script>
import { api } from '@/api/axios-base'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import KnSelect from '../inputs/KnSelect.vue'
import KnCheckBox from '../inputs/KnCheckBox.vue'
export default {
  components: { KnSelect, KnCheckBox },
  mixins: [validationFormMixin],
  props: {
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      row: null,
      institutions: [],
      groups: [],
      employeeTypes: []
    }
  },
  async created() {
    this.institutions = await this.fetchEducationalInstitution()
    const institutionId = this.model.id_institucion_educativa
    if (institutionId !== null 
      && institutionId !== undefined 
      && institutionId !== '') {
        this.fullArraysByIE(institutionId)
    }
  },
  methods: {
    async fetchEducationalInstitution() {
      try {
        const response = await api.get('/personas/api/institucion-educativa-all/')
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener intitutos educativos.', error);
      }
    },
    async fetchGroupsByEI(institutionId){
      try {
        const response = await api.get(`/personas/api/grupo-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener grupos por institucion educativa');
      }
    },
    async fetchEmployeeTypesByEI(institutionId){
      try {
        const response = await api.get(`/personas/api/tipo-empleado-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener grupos por institucion educativa');
      }
    },
    async getGroups(institutionId) {
      this.groups = await this.fetchGroupsByEI(institutionId)
    },
    async getEmployeeTypes(institutionId) {
      this.employeeTypes = await this.fetchEmployeeTypesByEI(institutionId)
    },
    async fullArraysByIE(institutionId) {
      this.groups = await this.fetchGroupsByEI(institutionId)
      this.employeeTypes = await this.fetchEmployeeTypesByEI(institutionId)
    }
  }
}
</script>

<style>

</style>