<template>
  <v-row align="center" justify="center">
    <v-col
      class="py-0 text-center"
      cols="12"
    >
      <v-btn
        class="mt-6"
        outlined
        tile
        @click="openFileInput"
      >
        <span class="text-caption text-none">Subir archivo de CV</span>
      </v-btn>
      <input
        :id="idInput"
        class="hidden-input-file"
        type="file"
        value="upload"
        @change="getFile()"
      />
      <p class="text-caption purple--text">{{ showFileName }}</p>
    </v-col>
    <p class="purple--text text-center">{{ label }}</p>
  </v-row>
</template>

<script>
export default {
  props: {
    idInput: {
      type: String,
      default: 'fileInput'
    },
    label: {
      type: String,
      default: ''
    },
    docObj: {
      type: Object,
      default: null
    },
    docType: {
      type: [String, Number],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      file: null,
      fileName: null
    }
  },
  computed: {
    showFileName() {
      return this.fileName !== null 
        ? this.multiple 
          ? this.fileName.join(', ') 
          : this.fileName 
        : 'No se ha seleccionado ningún archivo'
    }
  },
  created() {
    if (this.docObj !== null) {
      this.fileName = this.multiple 
                      ? this.docObj.map(file => file.fileName) 
                      : this.docObj.fileName
    }
  },
  methods: {
    openFileInput() {
      document.getElementById(this.idInput).click()
    },
    getFile() {
      // console.log(this.idInput)
      const input = document.getElementById(this.idInput)
      this.file = this.multiple ? [...input.files] : input.files[0]
      this.fileName = this.multiple ? this.file.map(file => file.name) : input.files[0].name
      // console.log('File? ', this.file);
      // console.log('Nombre de archivo? ', this.fileName);
      this.emitFile()
    },
    clearFile() {
      this.emitClearFile(this.file)
      this.file = null
      this.fileName = null
    },
    emitFile() {
      this.$emit('saveFile', {
        file: this.file,
        fileName: this.fileName,
        type: this.docType,
      })
    },
    emitClearFile() {
      this.$emit('clearFile', {file: this.file, type: this.docType})
    }
  }
}
</script>

<style>
</style>