<template>
  <v-row :align="columnAlign" :justify="columnJustify">
    <v-col v-if="column" class="py-0 text-center" cols="12">
      <v-btn
        class="mt-6"
        color="primary"
        outlined
        tile
      >
        <span class="text-caption text-none">
          {{ truncateText }}
        </span>
      </v-btn>
      <v-row
        class="my-3"
        :align="columnAlign" 
        :justify="columnJustify"
      >
        <v-btn
          color="info"
          text
          tile
          small
          @click="downloadDoc"
        >
          <span class="text-none">Descargar</span>
        </v-btn>
        <v-divider class="info" vertical />
        <v-btn
          color="info"
          text
          tile
          small
          @click="emitDelete"
        >
          <span class="text-none">Eliminar</span>
        </v-btn>
      </v-row>
    </v-col>
    <p v-if="column" class="purple--text">{{ label }}</p>
    <v-col
      v-if="!column"
      class="py-0"
      cols="12"
      md="4"
      lg="4"
      xl="4"
    >
      <v-subheader>{{ label }}</v-subheader>
    </v-col>
    <v-col
      v-if="!column"
      class="py-0"
      :cols="colsBtn"
    >
      <v-btn
        class="mt-1"
        color="primary"
        outlined
        tile
        block
        disabled
      >
        <span class="text-caption text-none text-truncate">
          {{ truncateText }}
        </span>
      </v-btn>
    </v-col>
    <v-col
      v-if="!column"
      class="py-0"
      :cols="colsActionBtns"
    >
      <v-row
        class="my-2"
        :align="columnAlign" 
        :justify="columnJustify"
      >
        <v-btn
          color="info"
          text
          tile
          small
          @click="downloadDoc"
        >
          <span class="text-none">Descargar</span>
        </v-btn>
        <v-divider class="info" vertical />
        <v-btn
          color="info"
          text
          tile
          small
          @click="emitDelete"
        >
          <span class="text-none">Eliminar</span>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    doc: {
      type: Object,
      default: () => null
    },
    label: {
      type: String,
      default: ''
    },
    colBtn: {
      type: String,
      default: '3'
    },
    column: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    colsActionBtns() {
      return this.$vuetify.breakpoint.mobile ? '6' :  8 - this.colBtn
    },
    columnAlign() {
      return this.column ? 'center' : 'start'
    },
    columnJustify() {
      return this.column ? 'center' : 'start'
    },
    docName() {
      return this.doc.nombre_documento
    },
    truncateText() {
      return this.docName.length > 18 ? `${this.docName.substring(0, 15)}...` : this.docName
    },
    colsBtn() {
      return this.$vuetify.breakpoint.mobile
              ? '6' 
              : this.colBtn
    }
  },
  methods: {
    downloadDoc() {
      window.location.href = this.doc.documento
    },
    emitDelete() {
      this.$emit('deleteDoc', this.doc)
    }
  }
}
</script>

<style scoped>
.v-btn.v-btn--disabled {
  color: #0047BA !important;
}
</style>